import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import Loading from '@/components/widgets/Loading.vue'
import Vue from 'vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String
    },
    id: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      code: { required },
      shortname: { required },
      name: { required },
      phone_number: { required },
      address: { required },
      account_name: {  },
      account_number: {  },
      account_bank: {  },
      company_name: { required },
      tax_id: { required },
    },
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      preview: null,
      showFileInput: true,
      inputType: 'file',
      fileError: '',
      file: null,
      form: {
        image: null,
        code: null,
        shortname: null,
        name: null,
        phone_number: null,
        email: null,
        facebook: null,
        line: null,
        address: null,
        description: null,
        account_name: null,
        account_number: null,
        account_bank: null,
        company_name: null,
        tax_id: null,
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.showFileInput = true
        this.drawerOpened = n
        if (this.mode === 'update') {
          this.onInitData()
        }
      }
    }
  },
  methods: {
    resetForm () {
      this.form.image = null
      this.form.code = null
      this.form.shortname = null
      this.form.name = null
      this.form.phone_number = null
      this.form.email = null
      this.form.facebook = null
      this.form.line = null
      this.form.address = null
      this.form.description = null
      this.form.account_name = null
      this.form.account_number = null
      this.form.account_bank = null
      this.form.company_name = null
      this.form.tax_id = null

      this.$nextTick(() => {
        this.$v.$reset();
      })

      this.onResetFileInput()
    },
    formBuilder(obj) {
      this.form._id = obj.data?.uuid ?? ''
      this.form.code = obj.data?.code ?? ''
      this.form.shortname = obj.data?.shortname ?? ''
      this.form.name = obj.data?.name ?? ''
      this.form.phone_number = obj.data?.phone_number ?? ''
      this.form.email = obj.data?.email ?? ''
      this.form.facebook = obj.data?.facebook ?? ''
      this.form.line = obj.data?.line ?? ''
      this.form.address = obj.data?.address ?? ''
      this.form.description = obj.data?.description ?? ''
      this.form.account_name = obj.data?.account_name ?? ''
      this.form.account_number = obj.data?.account_number ?? ''
      this.form.account_bank = obj.data?.account_bank ?? ''
      this.form.company_name = obj.data?.company_name ?? ''
      this.form.tax_id = obj.data?.tax_id ?? ''

      this.$v.form.$touch()
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    async onInitData () {
      try {
        const param = {
          _id: this.id
        }
        const result = await this.$store.dispatch('Branches/get', param, { root: true })
        if (result.status === 200) {
          this.formBuilder(result)
        }
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.loading = true
        const formData = new FormData()
        if (this.form.image) {
          formData.append('image', this.form.image)
        }
        formData.append('code', this.form.code)
        formData.append('shortname', this.form.shortname)
        formData.append('name', this.form.name)
        formData.append('phone_number', this.form.phone_number)
        formData.append('email', this.form.email)
        formData.append('line', this.form.line)
        formData.append('facebook', this.form.facebook)
        formData.append('address', this.form.address)
        formData.append('description', this.form.description)
        formData.append('account_name', this.form.account_name)
        formData.append('account_number', this.form.account_number)
        formData.append('account_bank', this.form.account_bank)
        formData.append('company_name', this.form.company_name)
        formData.append('tax_id', this.form.tax_id)

        let result = {}
        if (this.mode === 'create') {
          result = await this.$store.dispatch('Branches/create', formData, { root: true })
        } else {
          const body = {
            _id: this.id,
            formData: formData
          }
          result = await this.$store.dispatch('Branches/update', body, { root: true })
        }
        this.loading = false    
        setTimeout(() => {
          if (result.status === 201 || result.status === 200) {
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว"}</span>`+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
            }).then(result => {
              this.onCloseDrawer()
            })
          }
        }, 500)
      } catch (error) {
        this.loading = false 
        this.onExceptionHandler(error.response.data.message);
      }
    },
    onFileHandler(e) {
      this.$refs.fileInput.click()
    },
    onResetFileInput() {
      this.inputType = 'text'      
      this.$nextTick(() => {
        this.fileError = ''
        this.file = null
        this.inputType = 'file'    
      })
    },
    onFileChange(e) {
      if (e.target.files.length > 0) { 
        this.fileError = ''
        const selectedFile = e.target.files[0]
        // File type validation (accept only image files)
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg']
        if (!allowedFileTypes.includes(selectedFile.type)) {
          this.fileError = 'ไฟล์รูปภาพไม่ถูกต้อง. กรุณาเลิอกไฟล์รูปภาพ.'
          return;
        } 
        
        this.onFIletoBase64(selectedFile).then(file => {
          this.file = file
          this.form.image = file
        }).catch(error => {
          this.onExceptionHandler(error.message)
        })
      }      
    },
    onFIletoBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    }
  }
}
